import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Button,
	FormControl,
	FormErrorIcon,
	FormErrorMessage,
	FormLabel,
	Heading,
	Icon,
	Input,
	Modal,
	ModalBody,
	ModalContent,
	ModalOverlay,
	SlideFade,
	Text,
	Textarea,
	useToast,
	VStack,
} from "@chakra-ui/react"
import { useFormik } from "formik"
import isNil from "lodash/isNil"
import omitBy from "lodash/omitBy"
import React, { useState } from "react"
import { ArrowRight } from "react-feather"
import * as yup from "yup"
import { RAZORPAY_KEY_ID } from "../../constants"
import {
	ExtendedStudentFragment,
	FinishAddPaytankFundsOrderMutationVariables,
	useCreateAddPaytankFundsOrderMutation,
	useFinishAddPaytankFundsOrderMutation,
	useMeQuery,
} from "../../graphql"
import { loadScript } from "../../utils"

export type AddPaytankFundsModalProps = {
	isOpen: boolean
	onClose: () => void
	student: ExtendedStudentFragment
}

export const AddPaytankFundsModal: React.FC<AddPaytankFundsModalProps> = ({ isOpen, onClose }) => {
	const [, createOrder] = useCreateAddPaytankFundsOrderMutation()
	const [, finishOrder] = useFinishAddPaytankFundsOrderMutation()

	const [error, setError] = useState("")
	const [isLoading, setIsLoading] = useState(false)

	const toast = useToast()

	const [{ data: meData }] = useMeQuery()

	const handlePayment = async ({ amount, description }: { amount: number; description?: string }) => {
		setIsLoading(true)

		const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js")

		if (!res) {
			setIsLoading(false)
			setError("Razorpay SDK failed to load. Try again")
			return
		}

		const { error, data } = await createOrder({ amount, description })

		if (error) {
			setIsLoading(false)
			return setError(error.message)
		}

		if (!data?.createAddPaytankFundsOrder) {
			setIsLoading(false)
			return setError("Something went wrong, try again")
		}

		const { orderId } = data.createAddPaytankFundsOrder

		if (!orderId) {
			setIsLoading(false)
			return setError("Could not generate a valid order, please try again")
		}

		const prefill = omitBy(
			{
				name: meData?.me?.name,
				email: meData?.me?.email,
				contact: meData?.me?.phone ? "91" + meData.me.phone.toString() : undefined,
			},
			isNil
		)

		const readonly = omitBy(
			{
				name: Boolean(meData?.me?.name),
				email: Boolean(meData?.me?.email),
				contact: Boolean(meData?.me?.phone),
			},
			isNil
		)

		const options = {
			key: RAZORPAY_KEY_ID,
			order_id: orderId,
			amount: (amount * 100).toString(),
			currency: "INR",
			name: "Paytank Wallet",
			description,
			prefill,
			readonly,
			handler: async function (response: any) {
				setIsLoading(true)
				const input: FinishAddPaytankFundsOrderMutationVariables["input"] = {
					orderId,
					amount,
					razorpayPaymentId: response.razorpay_payment_id,
					razorpayOrderId: response.razorpay_order_id,
					razorpaySignature: response.razorpay_signature,
				}

				const { error, data } = await finishOrder({ input })

				if (error) {
					setIsLoading(false)
					return setError(error.message)
				}

				if (!data?.finishAddPaytankFundsOrder) {
					setIsLoading(false)
					return setError("Something went wrong, try again")
				}

				toast({
					title: "Payment successfull",
					description: "Funds added to your paytank wallet successfully",
					status: "success",
				})

				setIsLoading(false)
				onClose()
				return
			},
		}

		if (!(window as any).Razorpay) {
			setIsLoading(false)
			setError("Razorpay SDK failed to load. Try again")
			return
		}

		const rzrp = new (window as any).Razorpay(options)

		if (rzrp) {
			rzrp.open()
			rzrp.on("payment.failed", (response: any) => {
				setIsLoading(false)
				setError(response.error.description)
				return
			})
			setIsLoading(false)
			return
		}
	}

	const formik = useFormik({
		initialValues: { amount: 0, description: "" },
		onSubmit: handlePayment,
		validationSchema: yup.object({ amount: yup.number().min(0).required().label("Amount"), description: yup.string().nullable().label("Description") }),
	})

	return (
		<>
			<Modal isOpen={isOpen} onClose={onClose} size="sm">
				<ModalOverlay>
					<ModalContent>
						<ModalBody as={VStack} w="full" align="stretch" p="12">
							{error && (
								<Alert variant="subtle" status="error" flexDirection="column">
									<AlertIcon />
									<AlertTitle>Payment Failed</AlertTitle>
									<AlertDescription fontSize="sm">{error}</AlertDescription>
								</Alert>
							)}
							<form onSubmit={formik.handleSubmit}>
								<VStack w="full" align="flex-start" spacing="4">
									<Heading fontSize="lg" fontWeight="semibold" lineHeight="100%" color="#515151">
										Add Funds
									</Heading>
									<FormControl isInvalid={Boolean(formik.errors.amount && formik.touched.amount)}>
										<SlideFade in={Boolean(formik.values.amount)} unmountOnExit>
											<FormLabel htmlFor="email" fontSize="sm">
												Amount
											</FormLabel>
										</SlideFade>
										<Input
											type="number"
											placeholder="Amount"
											border="none"
											_focus={{ border: "none" }}
											maxW="sm"
											bg="white.500"
											rounded="xl"
											py="1"
											autoComplete="off"
											{...formik.getFieldProps("amount")}
										/>
										<FormErrorMessage>
											<FormErrorIcon />
											<Text>{formik.errors.amount}</Text>
										</FormErrorMessage>
									</FormControl>
									<FormControl isInvalid={Boolean(formik.errors.description && formik.touched.description)}>
										<SlideFade in={Boolean(formik.values.description)} unmountOnExit>
											<FormLabel htmlFor="description" fontSize="sm">
												Description (Optional)
											</FormLabel>
										</SlideFade>
										<Textarea
											placeholder="Description (Optional)"
											border="none"
											_focus={{ border: "none" }}
											maxW="sm"
											bg="white.500"
											rounded="xl"
											py="1"
											{...formik.getFieldProps("description")}
										/>
										<FormErrorMessage>
											<FormErrorIcon />
											<Text>{formik.errors.description}</Text>
										</FormErrorMessage>
									</FormControl>
									<Button type="submit" colorScheme="primary" size="md" rightIcon={<Icon as={ArrowRight} />} isLoading={isLoading}>
										Add
									</Button>
								</VStack>
							</form>
						</ModalBody>
					</ModalContent>
				</ModalOverlay>
			</Modal>
		</>
	)
}
